import { inject } from '@angular/core';
import {
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { Facility, FacilityService } from 'src/app/shared/services/facility/facility.service';
import { awsConfig } from '../../../../../../../../libs/includes/aws-export';

export const facilityResolver: ResolveFn<any> =
  (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const facilityService = inject(FacilityService);
    const appState = inject(AppStateService);
    let facilityId: string;

    if (route.params.facilityId) {
      facilityId = route.params.facilityId as string;
      appState.set('currentFacilityId', facilityId);
    } else {
      facilityId = appState.get<string>('currentFacilityId');
    }

    // there is a chance that currentUser is not yet available
    if (!facilityId && appState.currentUser.userType === 'SUPER_ADMIN') {
      // redirect back to Admin if we don't have a APOLLO_FACILITY_ID
      window.location.href = awsConfig.adminAppUrl;
    }

    // check cache for this facility. Couldn't get `shareReplay(1)` to work
    const facility = appState.get<Facility>('currentFacility');
    if (!facility) {
      return facilityService.getFacility(facilityId);
    }
    return undefined;
  };
